import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  TextField,
  Paper,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { VpnKey as VpnKeyIcon } from "@material-ui/icons";

import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import qoreContext, { client } from "../../qoreContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    outline: "none",
    borderRadius: 8,
    padding: theme.spacing(4),
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%, -50%)",
  },
  paper: {
    boxShadow: "0px 4px 10px rgba(107, 115, 128, 0.05)",
    borderRadius: 4,
  },
}));

const ProfilePassword = () => {
  const profile = useSelector((state) => state.profile);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openAuthForm, setOpenAuthForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const classes = useStyles();

  const { updateRow } = qoreContext.view("allMember").useUpdateRow();

  useEffect(() => {
    setUserId(profile.user?.data.id);
    setEmail(profile.user?.data.email);
  }, [profile]);

  const handleAuthPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    await client
      .authenticate(email, password)
      .then((token) => {
        setInvalidPassword(false);
        setOpenAuthForm(false);
        setOpenEditForm(true);
      })
      .catch((error) => {
        setInvalidPassword(true);
      });
    setLoading(false);
    setPassword("");
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!invalidPassword) {
      await updateRow(userId, {
        password: newPasswordConfirm,
      }).then((data) => {
        setLoading(false);
      });
      setOpenEditForm(false);
      // empty fields
      setPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
    }
  };

  const handleValidatePassword = () => {
    setInvalidPassword(!(newPassword.localeCompare(newPasswordConfirm) === 0));
  };

  const authForm = (
    <Modal open={openAuthForm} onClose={() => setOpenEditForm(false)}>
      <Box className={classes.modal}>
        <Typography variant="h5">Verifikasi Kata Sandi</Typography>
        <form onSubmit={handleAuthPassword}>
          <Box marginTop={2}>
            <Typography variant="body2">Kata sandi saat ini</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              type="password"
              required
              placeholder="Masukan kata sandi yang anda pakai saat ini"
              autoFocus
              value={password}
              fullWidth
              error={invalidPassword}
              onChange={(e) => setPassword(e.target.value)}
            ></TextField>

            <Collapse in={invalidPassword}>
              <Alert severity="warning">Kata Sandi Salah</Alert>
            </Collapse>
          </Box>

          <Box marginTop={2} display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              style={{ marginRight: 8 }}
              onClick={() => setOpenAuthForm(false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              startIcon={loading ? <CircularProgress size={16} /> : null}
              variant="text"
              color="primary"
              disabled={loading}
            >
              {loading ? "Verifikasi" : "Verifikasi"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );

  const editForm = (
    <Modal open={openEditForm} onClose={() => setOpenEditForm(false)}>
      <Box className={classes.modal}>
        <Typography variant="h5">Buat Kata Sandi Baru</Typography>
        <form onSubmit={handleUpdate}>
          <Box marginTop={1}>
            <Typography variant="body2">Kata Sandi Baru</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              type="password"
              required
              placeholder="Kata sandi baru"
              autoFocus
              value={newPassword}
              fullWidth
              onChange={(e) => setNewPassword(e.target.value)}
              onKeyUp={() => {
                if (newPasswordConfirm !== "") {
                  handleValidatePassword();
                }
              }}
            ></TextField>
          </Box>
          <Box marginTop={1}>
            <Typography variant="body2">Konfirmasi Kata Sandi</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              type="password"
              required
              placeholder="Ketik ulang kata sandi"
              value={newPasswordConfirm}
              fullWidth
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              error={invalidPassword}
              onKeyUp={handleValidatePassword}
            ></TextField>

            <Collapse in={invalidPassword}>
              <Alert severity="warning">Kata sandi tidak sama</Alert>
            </Collapse>
          </Box>

          <Box marginTop={2} display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              style={{ marginRight: 8 }}
              onClick={() => setOpenEditForm(false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              startIcon={loading ? <CircularProgress size={16} /> : null}
              variant="text"
              color="primary"
              disabled={loading || invalidPassword || newPasswordConfirm === ""}
            >
              {loading ? "Menyimpan" : "Simpan"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );

  return (
    <Paper
      className={classes.paper}
      elevation={0}
      padding={4}
      marginBottom={2}
      component={Box}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          Kata Sandi
        </Typography>
        <Box display="flex" alignItems="center">
          <Button
            startIcon={<VpnKeyIcon />}
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenAuthForm(true)}
          >
            Ubah Kata Sandi
          </Button>
          {authForm}
          {editForm}
        </Box>
      </Box>
    </Paper>
  );
};

export default ProfilePassword;
