import { client } from "../../qoreContext";

export const getCurrentUser = () => {
  return async (dispatch) => {
    const currentUser = await client.currentUser();
    dispatch({
      type: "CURRENT_USER",
      user: currentUser,
    });
  };
};