import React from "react";
import { Button, Container, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ArrowBack } from "@material-ui/icons";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProfileAvatar from "./profile-avatar";
import ProfileData from "./profile-data";
import ProfilePassword from "./profile-password";

const PreLoading = () => (
  <Container maxWidth="md">
    <Box marginBottom={2} marginTop={10}>
      <Skeleton variant="rect" width={"100%"} height={150} />
    </Box>

    <Box marginBottom={2}>
      <Skeleton variant="rect" width={"100%"} height={200} />
    </Box>

    <Box marginBottom={2}>
      <Skeleton variant="rect" width={"100%"} height={150} />
    </Box>
  </Container>
);

export default function Profile() {
  const profile = useSelector(state => state.profile);
  return (
    <>
      {profile.user !== null ? (
        <main style={{ flexGrow: 1, padding: 20 }}>
          <Container maxWidth="md">
            <Box marginY={2}>
              <Button
                startIcon={<ArrowBack />}
                size="small"
                variant="text"
                color="primary"
                component={Link}
                to="/"
              >
                Kembali ke Halaman Utama
              </Button>
            </Box>

            <ProfileAvatar />
            <ProfileData />
            <ProfilePassword />
          </Container>
        </main>
      ) : (
        <PreLoading />
      )}
    </>
  );
}
