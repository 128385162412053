import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  Box,
  Modal,
  Grid,
  Paper,
  Container,
  ButtonGroup,
  Input,
  Tabs,
  Tab,
  Link as MLink,
  Breadcrumbs,
  InputAdornment,
} from "@material-ui/core";
import { useParams, Link } from "react-router-dom";
import { Search } from "@material-ui/icons";
import qoreContext, { client } from "../../../qoreContext";
import LessonCard from "./LessonCard";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0 5em 0em 5em",
  },
  modal: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid blue",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  mod: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "flex",
  },
  add: {
    display: "flex",
    margin: "25px 0 10px 0",
    justifyContent: "space-between",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function Module() {
  const classes = useStyles();
  const { subjectId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [materi, setMateri] = useState("");
  const [desc, setDesc] = useState("");
  const [video, setVideo] = useState("");
  const [image, setImage] = useState("");
  // const [buka, setBuka] = React.useState(false);
  // const [edit, setEdit] = useState("");
  // const [toogle, setToogle] = React.useState(0);
  // const [searchTerm, setSearchTerm] = useState("");

  const { data: subject, status } = qoreContext
    .view("allSubject")
    .useGetRow(subjectId);

  const { data: lessons } = qoreContext
    .view("subjectLesson")
    .useListRow({ subjectId });

  const { insertRow } = qoreContext.view("allLesson").useInsertRow();

  const { addRelation } = qoreContext.view("allSubject").useRelation(subjectId);

  // const { updateRow } = qoreContext.view("allSubject").useUpdateRow();

  const handleUpload = async (event) => {
    const file = event.currentTarget.files?.item(0);
    if (!file) return;
    const url = await client.view("allLesson").upload(file);
    setImage(url);
  };

  async function addModule(e) {
    e.preventDefault();
    await insertRow({
      title: materi,
      description: desc,
      url: video,
      image: image,
    }).then((data) => {
      console.log(data.id, ">>> data");
      let idMod = data.id;
      return addModuleRelation({ e, idMod });
    });
  }

  async function addModuleRelation({ e, idMod }) {
    e.preventDefault();
    await addRelation({
      module: [idMod],
    }).then(handleClose);
    window.location.reload();
  }

  // async function handleEditModule(e) {
  //   e.preventDefault();
  //   await updateRow(subjectId, {
  //     name: edit,
  //   }).then(handleTutup);
  // }

  // const handleGoBack = () => {
  //   window.history.back();
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    // e.preventDefault();
    setOpen(false);
  };

  // const handleBuka = () => {
  //   setBuka(true);
  // };

  // const handleTutup = (e) => {
  //   setBuka(false);
  // };

  // const handleSwitch = (e, newValue) => {
  //   setToogle(newValue);
  // };

  const body = (
    <form onSubmit={addModule} className={classes.modal}>
      <Typography variant="h6">Tambah Materi</Typography>
      <div style={{ marginTop: 15 }}>
        <Typography style={{ marginBottom: -10 }}>Judul</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="judul"
          label="judul"
          name="judul"
          autoComplete="judul"
          value={materi}
          onChange={(e) => setMateri(e.target.value)}
        ></TextField>
      </div>
      <div style={{ margin: "15px 0 15px 0" }}>
        <div style={{ display: "flex", marginBottom: -10 }}>
          <Typography style={{ marginRight: 5 }}>Deskripsi</Typography>
          <Typography style={{ color: "GrayText" }}>(Optional)</Typography>
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="description"
          label="Deskripsi"
          name="description"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        ></TextField>
      </div>
      <div style={{ marginBottom: 5 }}>
        <div style={{ display: "flex", marginBottom: -10 }}>
          <Typography style={{ marginRight: 5 }}>URL Video/Artikel</Typography>
          <Typography style={{ color: "GrayText" }}>(Optional)</Typography>
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          id="video"
          label="Optional"
          name="video"
          fullWidth
          value={video}
          onChange={(e) => setVideo(e.target.value)}
        ></TextField>
      </div>
      <div style={{ display: "flex", marginBottom: 1 }}>
        <Typography style={{ marginRight: 5 }}>Upload Foto</Typography>
        <Typography style={{ color: "GrayText" }}>(Optional)</Typography>
      </div>
      <div style={{ display: "flex", marginBottom: 10 }}>
        <Input type="file" onChange={handleUpload}></Input>
      </div>
      <ButtonGroup style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="text" size="small" color="primary" type="submit">
          Tambah
        </Button>
        <Button
          variant="text"
          size="small"
          color="secondary"
          onClick={handleClose}
        >
          Tutup
        </Button>
      </ButtonGroup>
    </form>
  );

  return (
    <>
      {status === "success" ? (
        <>
          <Box style={{ backgroundColor: "white" }}>
            <Paper square>
              <Box paddingY={6}>
                <Container>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Link component={MLink} to="/">
                      Semua Kelas
                    </Link>
                    <Link
                      component={MLink}
                      to={`/classroom/${subject.classroom.nodes[0].id}`}
                    >
                      {subject.classroom.nodes[0].displayField}
                    </Link>
                    <Typography color="textPrimary">{subject.name}</Typography>
                  </Breadcrumbs>

                  <Box paddingY={2}>
                    <Typography variant="h4">
                      Pelajaran {subject.name} untuk kelas{" "}
                      {subject.classroom.nodes[0].displayField}
                    </Typography>
                  </Box>
                </Container>
              </Box>

              <Container>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  value={0}
                >
                  <Tab label="Pelajaran" value={0} />
                  <Tab label="Tugas" value={1} />
                  <Tab label="Nilai" value={2} />
                </Tabs>
              </Container>
            </Paper>
          </Box>
          <main style={{ flexGrow: 1, padding: 30 }}>
            <Container className={classes.container}>
              <Box marginTop={4}>
                <Box
                  marginBottom={4}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">
                    Semua Materi ({subject.lesson.totalCount})
                  </Typography>
                  <TextField
                    placeholder="Cari materi"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    // onChange={(e) => {
                    //   setSearchTerm(e.target.value);
                    // }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    style={{ width: 200 }}
                    disableElevation
                  >
                    Tambah Materi
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {body}
                  </Modal>
                </Box>

                <div className={classes.mod}>
                  <Grid container>
                    {lessons.map((node, idx) => {
                      return (
                        <Grid container direction="column">
                          <LessonCard lesson={node} key={idx} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Box>
            </Container>
          </main>
        </>
      ) : null}
    </>
  );
}
