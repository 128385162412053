import React from "react";
import {
  Box,
  Typography,
  Paper,
  Container,
  Tabs,
  Tab,
} from "@material-ui/core";
import qoreContext from "../../qoreContext";
import { useParams } from "react-router-dom";
import { AccountCircle } from "@material-ui/icons";
import RoomHeader from "./RoomHeader";

import SubjectHeader from "./SubjectHeader";
import SubjectList from "./SubjectList";

export default function InsideClassroom() {
  const { someClassroomId } = useParams();
  const [activeTab, setActiveTab] = React.useState(0);

  const {
    data: classroom,
    status,
    revalidate: roomRevalidate,
  } = qoreContext.view("allClassroom").useGetRow(someClassroomId);

  const { data: subjects, revalidate: subjectRevalidate } = qoreContext
    .view("classroomSubject")
    .useListRow(
      {
        roomId: someClassroomId,
      },
      { networkPolicy: "cache-only" }
    );

  const handleSwitch = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {status === "success" ? (
        <>
          <Box style={{ backgroundColor: "white" }}>
            <Paper>
              <RoomHeader room={classroom} onUpdated={roomRevalidate} />
              <Container>
                <Tabs
                  value={activeTab}
                  onChange={handleSwitch}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Mata Pelajaran" />
                  <Tab label="Anggota Kelas" />
                </Tabs>
              </Container>
            </Paper>
          </Box>

          <main style={{ flexGrow: 1 }}>
            <Container maxWidth="lg">
              <Box hidden={!(activeTab === 0)}>
                <SubjectHeader
                  subjectCount={subjects.length}
                  subjectRevalidate={subjectRevalidate}
                />
                <SubjectList
                  subjects={subjects}
                  subjectRevalidate={subjectRevalidate}
                />
              </Box>

              <Box hidden={!(activeTab === 1)} my={5}>
                <div
                  style={{
                    borderBottom: "1px solid rgba(171, 183, 183, 1)",
                    padding: "10px 0 10px 0",
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Guru ({classroom.teacher.totalCount})
                  </Typography>
                </div>
                {classroom.teacher.nodes.map((nod) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 10,
                        color: "#424242",
                      }}
                      key={nod.id}
                    >
                      <AccountCircle />
                      <Typography style={{ marginLeft: 5 }}>
                        {nod.displayField}
                      </Typography>
                    </div>
                  );
                })}

                <Box mt={4}>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(171, 183, 183, 1)",
                      padding: "10px 0 10px 0",
                    }}
                  >
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      Siswa ({classroom.student.totalCount})
                    </Typography>
                  </div>
                  {classroom.student.nodes.map((nod) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          color: "#424242",
                        }}
                        key={nod.id}
                      >
                        <AccountCircle />
                        <Typography style={{ marginLeft: 5 }}>
                          {nod.displayField}
                        </Typography>
                      </div>
                    );
                  })}
                </Box>
              </Box>
            </Container>
          </main>
        </>
      ) : null}
    </>
  );
}
