import { client } from "../../qoreContext";

export const login = ({ email, password }) => {
  return (dispatch) => {
    return client
      .authenticate(email, password)
      .then(async (token) => {
        localStorage.setItem("token", token);
        const currentUser = await client.currentUser();
        if (currentUser !== null) {
          dispatch({
            type: "LOGIN",
            payload: { user: currentUser },
          });
          localStorage.setItem("user_id", currentUser.data.id);
        }
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          dispatch({
            type: "AUTH_ERROR",
            payload: `Password yang Anda masukan tidak cocok dengan email ${escape(email)}. Cek kembali password yang Anda masukkan`,
          });
        }
      });
  };
};

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  return {
    type: "LOGOUT",
  };
}
