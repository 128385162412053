import { Grid } from "@material-ui/core";
import CardSubject from "../../components/cardSubject";

const SubjectList = ({ subjects, subjectRevalidate }) => {
  const subjectItem = subjects.map((subject) => {
    return (
      <Grid item sm={12} lg={3}>
        <CardSubject
          subject={subject}
          onUpdated={subjectRevalidate}
          key={subject.id}
        />
      </Grid>
    );
  });

  return (
    <Grid container spacing={2}>
      {subjectItem}
    </Grid>
  );
};

export default SubjectList;
