import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import authReducer from "./auth/reducer"
import profileReducer from "./profile/reducer"
import thunk from "redux-thunk";

const reducers = combineReducers({
  auth: authReducer,
  profile: profileReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = applyMiddleware(thunk);
const enhancers = composeEnhancers(middlewares);

const store = createStore(reducers, enhancers);

export default store;
