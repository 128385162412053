import React from "react";
import { useParams, Redirect, Link } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { client } from "../qoreContext";

function JoinRoom() {
  const { roomId } = useParams();
  const userId = localStorage.getItem("user_id");

  const [isLoading, setIsLoading] = React.useState(true);
  const [wasJoin, setWasJoin] = React.useState(false);
  const [joined, setJoined] = React.useState(false);

  React.useEffect(() => {
    const requestToJoin = async () => {
      await client
        .view("allClassroom")
        .addRelation(roomId, {
          teacher: [userId],
        })
        .then((data) => {
          setJoined(data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setWasJoin(true);
          }
        });
    };

    requestToJoin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const preloading = isLoading ? (
    <>
      <CircularProgress style={{ marginBottom: 32 }} />
      <Typography variant="h5">Mohon tunggu...</Typography>
      <Typography variant="body1">Sedang meminta untuk bergabung</Typography>
    </>
  ) : null;

  const joinSuccess = joined ? (
    <>
      <Typography variant="h5" style={{ marginBottom: 16 }}>
        Berhasil bergabung
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 16 }}>
        Selamat anda sudah bergabung di kelas
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={`/classroom/${roomId}`}
        disableElevation
      >
        Buka Kelas
      </Button>
    </>
  ) : null;

  return (
    <Container maxWidth="lg">
      <Box display="flex" mt={32} justifyContent="center">
        <Box align="center">
          {wasJoin ? <Redirect to={`/classroom/${roomId}`} /> : null}
          {preloading}
          {joinSuccess}
        </Box>
      </Box>
    </Container>
  );
}

export default JoinRoom;
