const initialState = {
  user: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CURRENT_USER":
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
}
