import React, { useState } from "react";
import {
  AppBar,
  Button,
  TextField,
  Container,
  Typography,
  makeStyles,
  Box,
  Collapse,
  InputAdornment,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/auth/actions";

import Logo from "../../image/brand-logo.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "16px 0",
    textTransform: "none",
    backgroundColor: "#2950D8",
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // auth state from redux
  const auth = useSelector((state) => state.auth);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    await dispatch(
      login({
        email,
        password,
      })
    );
    setLoading(false);
  };

  return (
    <>
      {auth.isLogin && (localStorage.getItem('user_id') !== null) ? <Redirect to="/" /> : null}
      <AppBar
        position="static"
        style={{
          boxShadow: "0px 4px 10px rgba(107, 115, 128, 0.07)",
          backgroundColor: "white",
        }}
      >
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="space-between" py={1}>
            <Box p={1} width="100%">
              <Link to={"/"}>
                <img alt="edit" src={Logo} />
              </Link>
            </Box>
            <Box p={1} flexShrink={0}>
              <Button
                component={Link}
                to={"/register"}
                variant="outlined"
                color="primary"
                width={200}
                style={{ textTransform: "none" }}
              >
                Buat Akun Baru
              </Button>
            </Box>
          </Box>
        </Container>
      </AppBar>

      <Container>
      <Box marginY={5}>
        <Typography variant="h4" align={'center'}>
          Masuk ke akun Anda
        </Typography>
      </Box>
      </Container>

      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Collapse in={auth.error}>
            <Alert severity="error" style={{ marginBottom: 28 }}>
              {auth.message}
            </Alert>
          </Collapse>
          <form onSubmit={handleLogin} className={classes.form}>
            <label>Email</label>
            <TextField
              variant="outlined"
              margin="normal"
              style={{ marginBottom: 28 }}
              required
              fullWidth
              id="email"
              type="email"
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Masukkan email Anda disini"
            />
            <label>Password</label>
            <TextField
              variant="outlined"
              margin="normal"
              style={{ marginBottom: 28 }}
              required
              fullWidth
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Masukkan password Anda disini"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              startIcon={loading ? <CircularProgress size={18}/> : null}
              disabled={loading}
              disableElevation
            >
              Masuk
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
}
