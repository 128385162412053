import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Footer from "../../components/Footer";
import ClassroomCard from "../../components/cardClass";
import qoreContext from "../../qoreContext";

import Header from "./Header";

import { ReactComponent as ChalkBoardIllustration } from "../../image/illustration/class-chalkboard.svg";

const useStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  margin: {
    margin: theme.spacing(1),
  },
  container: {
    padding: "0 5em 0em 5em",
  },
}));

function PreLoading({ error }) {
  if (error !== null) {
    return (
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center">
          <Typography variant="h1">Opps! Terjadi masalah</Typography>
          <Typography variant="body1">{error}</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <Skeleton variant="rect" width={"100%"} height={150} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Skeleton variant="rect" width={"100%"} height={150} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Skeleton variant="rect" width={"100%"} height={150} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Skeleton variant="rect" width={"100%"} height={150} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Skeleton variant="rect" width={"100%"} height={150} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Skeleton variant="rect" width={"100%"} height={150} />
        </Grid>
      </Grid>
    </Container>
  );
}

function Dashboard() {
  const classes = useStyle();

  const { data: classrooms, status, revalidate, error } = qoreContext
    .view("teacherClassroom")
    .useListRow({
      teacherId: localStorage.getItem("user_id"),
    });

  const emptyClassroom =
    status === "success" ? (
      <Container maxWidth="lg" style={{ textAlign: "center" }}>
        <ChalkBoardIllustration style={{ marginBottom: 32 }} />
        <Typography variant="h4" style={{ marginBottom: 16 }}>
          Belum ada kelas satupun
        </Typography>
        <Typography variant="body1">
          Mulai buat kelas dengan tekan tombol ‘Tambah Kelas’ atau ‘Gabung
          Kelas’ untuk mulai kelola kelas Anda
        </Typography>
      </Container>
    ) : (
      <PreLoading error={error} />
    );

  return (
    <>
      <CssBaseline />
      <Header onRoomAdded={revalidate} />
      {classrooms.length > 0 ? (
        <Container maxWidth="lg">
          <main style={{ flexGrow: 2 }}>
            <div className={classes.toolbar}>
              <Grid container spacing={3}>
                {classrooms.map((room, i) => {
                  return (
                    <Grid item md={4} sm={6} xs={12} key={i}>
                      <ClassroomCard
                        room={room}
                        key={i}
                        onUpdated={revalidate}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </main>
        </Container>
      ) : (
        emptyClassroom
      )}

      <Footer />
    </>
  );
}

export default Dashboard;
