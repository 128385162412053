import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Container, Grid, Box, Typography, Link as MuiLink } from "@material-ui/core";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import RegisterNavbar from "./RegisterNavbar";
import { ReactComponent as TeacherIllustration } from "../../image/illustration/teacher.svg";
import { ReactComponent as StudentIllustration } from "../../image/illustration/student.svg";

const useStyles = makeStyles((theme) => ({
  card: {

    border: "1px solid #D1D4DB",
    borderRadius: 4,
    boxShadow: "none",
    padding: "40px 28px",
    "&:hover": {
      backgroundColor: "rgba(66, 103, 233, 0.05)",
      border: "1px solid #4267E9",
    },
  },
  media: {
    height: "100px",
    width: "100px",
    marginBottom: 24,
  },
}));
export default function MediaCard() {
  const classes = useStyles();

  return (
    <>
      <RegisterNavbar />

      <Container maxWidth="lg">
        <Box align="center" marginY={5}>
          <Typography component="h1" variant="h4">
            Pendaftaran Akun.
            <br />
            Silahkan pilih jenis akun Anda
          </Typography>
        </Box>

        <Grid container spacing={6} justify={'space-between'}>
          <Grid item sm={6} xs={12} md={6}>
            <Link component={MuiLink}
              to="/register-teacher"
              underline={'none'}
            >
              <Card className={classes.card}>
              <Box align="center">
                <TeacherIllustration className={classes.media} />
              </Box>

              <Typography
                align="center"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginBottom: 12,
                }}
              >
                Akun Guru
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: 16, color: "#6B7380" }}
              >
                Digunakan oleh guru atau asisten guru untuk mengelola kelas,
                mengelola materi dan tugas
              </Typography>
              </Card>
            </Link>
          </Grid>

          <Grid item sm={6} xs={12} md={6}>
            <Link
            component={MuiLink}
              to="/register-student"
              underline={'none'}
            >
              <Card className={classes.card}>
              <Box align="center">
                <StudentIllustration className={classes.media} />
              </Box>

              <Typography
                align="center"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginBottom: 12,
                }}
              >
                Akun Siswa
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: 16, color: "#6B7380" }}
              >
                Digunakan oleh siswa untuk melihat materi <br /> dan tugas yang
                tersedia
              </Typography>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
