import React, { useEffect, useState } from "react";
import {
  MenuItem,
  MenuList,
  Typography,
  Avatar,
  IconButton,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  Hidden,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/actions";
import { getCurrentUser } from "../../store/profile/actions";
import { useHistory } from "react-router-dom";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

const ProfileMenu = function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const [user, setUser] = useState();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  

  // initial trigger to get current user
  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser(profile.user);
  }, [profile]);

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  const handleToProfile = () => {
    setOpen(false);
    history.push("/profile");
  };

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Avatar
        style={{ borderRadius: "4px", margin: "auto 1em" }}
        src={user?.data.avatar}
        onClick={handleToggle}
      ></Avatar>
      <Hidden smDown>
        <div style={{ marginRight: "1em" }}>
          <Typography
            variant="body1"
            display="block"
            noWrap
            style={{ fontWeight: 500 }}
          >
            {user?.data.name || <Skeleton variant="text" width={120} />}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            style={{ color: "#6B7380" }}
          >
            {user?.email || <Skeleton variant="text" width={120} />}
          </Typography>
        </div>
      
      
      <IconButton
        onClick={handleToggle}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
      >
        <ExpandMoreOutlinedIcon />
      </IconButton>

      </Hidden>

      <Popper
        open={open}
        placement="bottom-end"
        style={{
          width: 240,
          marginTop: 24,
        }}
        anchorEl={anchorEl}
        role={undefined}
        transition
      >
 

        <Grow in={open} style={{transformOrigin:"right top"}}>
          <Paper
            style={{
              boxShadow: "0px 4px 10px rgba(107, 115, 128, 0.05)",
              borderRadius: 4,
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={handleToProfile} style={{ padding: 16 }}>
                  <PersonOutlineOutlinedIcon style={{ marginRight: 12 }} />{" "}
                  Profile
                </MenuItem>
                <hr
                  style={{
                    borderTop: "1px solid #F3F4F6",
                    borderBottom: 0,
                    margin: "0 16px",
                  }}
                />
                <MenuItem
                  onClick={handleLogout}
                  style={{ color: "red", padding: 16 }}
                >
                  <ExitToAppOutlinedIcon style={{ marginRight: 12 }} /> Keluar
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      </Popper>
    </>
  );
};

export default ProfileMenu;
