import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Paper,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../store/profile/actions";
import qoreContext from "../../qoreContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    outline: "none",
    borderRadius: 8,
    padding: theme.spacing(4),
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%, -50%)",
  },
  paper: {
    boxShadow: "0px 4px 10px rgba(107, 115, 128, 0.05)",
    borderRadius: 4,
  },
}));

const ProfileData = () => {
  const profile = useSelector((state) => state.profile);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();

  const { updateRow } = qoreContext.view("allMember").useUpdateRow();

  useEffect(() => {
    setUserId(profile.user?.data.id);
    setName(profile.user?.data.name);
    setEmail(profile.user?.data.email);
  }, [profile]);

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);
    await updateRow(userId, {
      name: name,
      email: email,
    });
    await dispatch(getCurrentUser());
    setLoading(false);
    setOpenEditForm(false);
  };

  const editForm = (
    <Modal open={openEditForm} onClose={() => setOpenEditForm(false)}>
      <Box className={classes.modal}>
        <form onSubmit={handleUpdate}>
          <Box marginTop={2}>
            <Typography variant="body2">Nama</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              type="text"
              required
              placeholder="Nama Lengkap"
              autoFocus
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            ></TextField>
          </Box>
          <Box marginTop={1}>
            <Typography variant="body2">Email</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              type="email"
              required
              placeholder="Nama Lengkap"
              autoFocus
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            ></TextField>
          </Box>

          <Box marginTop={2} display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              style={{ marginRight: 8 }}
              onClick={() => setOpenEditForm(false)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              startIcon={loading ? <CircularProgress size={16} /> : null}
              variant="text"
              color="primary"
              disabled={loading}
            >
              {loading ? "Menyimpan" : "Simpan"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );

  return (
    <Paper
      className={classes.paper}
      elevation={0}
      padding={4}
      marginBottom={2}
      component={Box}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          Data Diri
        </Typography>
        <Box display="flex" alignItems="center">
          <Button
            startIcon={<EditIcon />}
            variant="text"
            size="small"
            color="primary"
            onClick={() => setOpenEditForm(true)}
          >
            Ubah Data
          </Button>
          {editForm}
        </Box>
      </Box>
      <Box borderBottom={1} marginY={2} style={{ borderColor: "#F3F4F6" }} />

      <Box>
        <div style={{ display: "flex" }}>
          <Typography style={{ color: "GrayText" }}>Nama: </Typography>
          <Typography style={{ marginLeft: "4px" }}>
            {profile.user?.data.name}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography style={{ color: "GrayText" }}>Email: </Typography>
          <Typography style={{ marginLeft: "4px" }}>
            {profile.user?.email}
          </Typography>
        </div>
      </Box>
    </Paper>
  );
};

export default ProfileData;
