import React from "react";
import { Container, Box, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import RegisterNavbar from "./RegisterNavbar";
import { ReactComponent as ApprovedIllustration } from "../../image/illustration/profile-approved.svg";

export default function RegisterSuccess() {
  return (
    <>
      <RegisterNavbar />

      <Container maxWidth="lg">
        <Box align="center" marginTop={10}>
          <Typography component="h1" variant="h4">
            Pendaftaran Berhasil.
            <br />
            Silahkan masuk ke akun anda
          </Typography>
        </Box>
        <Box align="center" marginTop={5}>
          <ApprovedIllustration width={400} />
        </Box>
        <Box align="center" marginTop={5}>
          <Button
            component={Link}
            to="/login"
            variant="contained"
            color="primary"
            disableElevation
          >
            Masuk Sekarang
          </Button>
        </Box>
      </Container>
    </>
  );
}
