import React, { useState, useCallback, useEffect } from "react";
import {
  Avatar,
  Button,
  Paper,
  Modal,
  Box,
  Slider,
  CircularProgress,
} from "@material-ui/core";

import CameraAltIcon from '@material-ui/icons/CameraAlt';

import Cropper from "react-easy-crop";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../store/profile/actions";
import qoreContext, { client } from "../../qoreContext";
import getCroppedImg from "../../utils/cropImage";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    outline: "none",
    borderRadius: 8,
    padding: theme.spacing(4),
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%, -50%)",
  },
  paper: {
    boxShadow: "0px 4px 10px rgba(107, 115, 128, 0.05)",
    borderRadius: 4,
  },
}));

const ProfileAvatar = () => {
  const profile = useSelector((state) => state.profile);
  const [openUploadForm, setOpenUploadForm] = useState(false);
  const [fileURL, setFileURL] = useState("");
  const [avatarURL, setAvatarURL] = useState();
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    // console.log(croppedArea, croppedAreaPixels);
  }, []);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { updateRow } = qoreContext.view("allMember").useUpdateRow();

  useEffect(() => {
    setAvatarURL(profile.user?.data.avatar);
  }, [profile]);

  const handlePickPhoto = (event) => {
    const file = event.currentTarget.files?.item(0);
    setFileURL(URL.createObjectURL(file));
    setOpenUploadForm(true);
  };

  const handleFileUpload = async () => {
    setLoading(true);
    const croppedImage = await getCroppedImg(fileURL, croppedAreaPixels);

    const userId = localStorage.getItem("user_id");
    const url = await client.view("allMember").upload(croppedImage);
    await updateRow(userId, { avatar: url });
    dispatch(getCurrentUser());
    setLoading(false);
    setOpenUploadForm(false);
  };

  const uploadForm = (
    <Modal open={openUploadForm} onClose={() => setOpenUploadForm(false)}>
      <Box className={classes.modal}>
        <Box style={{ position: "relative", width: "100%", height: 400 }}>
          <Cropper
            image={fileURL}
            crop={crop}
            zoom={zoom}
            // rotation={rotation}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            // cropSize={{ width: 200, height: 200 }}
          />
        </Box>
        <Box marginTop={2}>
          <label>Perbesar</label>
          <Slider
            value={zoom}
            valueLabelDisplay="auto"
            min={1}
            max={10}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Box>
        <Box marginTop={2} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            style={{ marginRight: 8 }}
            onClick={() => setOpenUploadForm(false)}
          >
            Batal
          </Button>
          <Button
            startIcon={loading ? <CircularProgress size={16} /> : null}
            variant="text"
            color="primary"
            disabled={loading}
            onClick={handleFileUpload}
          >
            {loading ? "Menyimpan" : "Simpan"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <Paper
      className={classes.paper}
      elevation={0}
      padding={4}
      marginBottom={2}
      component={Box}
      display="flex"
      justifyContent="space-between"
    >
      <Avatar style={{ width: 120, height: 120 }} src={avatarURL} />
      <Box display="flex" alignItems="center">
        <input
          accept="image/jpeg"
          className={classes.input}
          id="upload-photo"
          multiple
          type="file"
          onChange={handlePickPhoto}
          style={{ display: "none" }}
        />
        <label htmlFor="upload-photo">
          <Button
            startIcon={<CameraAltIcon />}
            variant="text"
            size="small"
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            Ubah Foto
          </Button>
        </label>
        {uploadForm}
      </Box>
    </Paper>
  );
};

export default ProfileAvatar;
