import { AUTH_ERROR, LOGIN, LOGOUT } from "./constants";

const initialState = {
  isLogin: false,
  user: null,
  error: false,
  message: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogin: true,
        error: false,
        message: "",
        user: action.payload.user
      };

    case LOGOUT:
      return {
        ...state,
        user: null,
        isLogin: false,
        error: false
      };

    case AUTH_ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
}
