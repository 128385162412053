import React from "react";
import { Container, Box, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as NotFoundIllustration } from "../image/illustration/error-404.svg";

export default function NotFoundPage() {
  return (
    <>
      <Container maxWidth="lg">
        <Box align="center" marginTop={10}>
          <Typography component="h1" variant="h4">
            Maaf Halaman tidak ditemukan,<br />
            Mungkin sudah dihapus.
          </Typography>
        </Box>
        <Box align="center" marginTop={5}>
          <NotFoundIllustration width={500} />
        </Box>
        <Box align="center" marginTop={5}>
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            disableElevation
          >
           Kembali
          </Button>
        </Box>
      </Container>
    </>
  );
}
