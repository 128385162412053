import React, { useState } from "react";
import {
  Collapse,
  Button,
  TextField,
  Typography,
  Container,
  Box,
  InputLabel,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import RegisterNavbar from "./RegisterNavbar";
import qoreContext from "../../qoreContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2, 0),
  },
}));

export default function RegisterStudent() {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailRegistered, setEmailRegistered] = useState(false);
  const [loading, setLoading] = useState(false);

  const { send } = qoreContext.view("allMember").useForm("newStudent");

  async function handleRegister(event) {
    event.preventDefault();
    setLoading(true);
    await send({
      email,
      password,
      name,
    })
      .then(() => {
        setLoading(false);
        history.push("/register-success");
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 409") {
          setEmailRegistered(true);
          setLoading(false);
        }
      });
  }

  return (
    <>
      <RegisterNavbar />
      <Box align="center" marginY={8}>
        <Typography component="h1" variant="h4">
          Lengkapi data diri Anda sebagai Guru
        </Typography>
        <Typography variant="body1">
          Mulai daftarkan akun untuk mengelola kelas, materi dan tugas
        </Typography>
      </Box>
      <Container component="main" maxWidth="sm">
        <Collapse in={emailRegistered}>
          <Alert severity="warning" style={{ marginBottom: 28 }}>
            Alamat email <b>{email}</b> sudah terdaftar. Gunakan alamat email
            yang lain.
          </Alert>
        </Collapse>
        <form onSubmit={handleRegister} className={classes.form}>
          <Box marginBottom={2}>
            <InputLabel htmlFor="name" style={{ marginBottom: 10 }}>
              Nama Lengkap*
            </InputLabel>
            <TextField
              id="name"
              variant="outlined"
              placeholder="Masukan nama lengkap Anda disini"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
            />
          </Box>
          <Box marginBottom={2}>
            <InputLabel htmlFor="email" style={{ marginBottom: 10 }}>
              Email*
            </InputLabel>
            <TextField
              id="email"
              type="email"
              variant="outlined"
              placeholder="Alamat email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
            />
          </Box>
          <Box marginBottom={2}>
            <InputLabel htmlFor="password" style={{ marginBottom: 10 }}>
              Kata Sandi*
            </InputLabel>
            <TextField
              id="password"
              variant="outlined"
              required
              fullWidth
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disableElevation
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                color="inherit"
                style={{ marginRight: 8 }}
                size={16}
              />
            ) : null}
            Register
          </Button>
        </form>
      </Container>
    </>
  );
}
