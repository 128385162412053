import { QoreClient } from "@feedloop/qore-client";
import createQoreContext from "@feedloop/qore-react";
import config from "./qore.config.json";
import schema from "./qore.schema.json";

export const client = new QoreClient({
  ...config,
  onError: (error) => {
    switch (error.message) {
      case "Request failed with status code 500":
        console.info("An error has occured");
        break;
      case "Request failed with status code 401":
        console.info("Email or Password wrong");
        break;
      case "Request failed with status code 404":
        console.info("Ups page not found");
        break;
      case "Network Error":
        console.info("Network unavailable");
        window.location.replace('/offline');
        break;
      default:
        console.info(`ERROR: ${error.message}`);
    }
  },
  getToken: () => localStorage.getItem("token"),
});
client.init(schema);

const qoreContext = createQoreContext(client);
export default qoreContext;
