import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Modal,
  Collapse,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import qoreContext, { client } from "../../qoreContext";
import uniqid from "uniqid";

function getModalStyle() {
  return {
    margin: "auto",
  };
}

const useStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%, -50%)",
  },
  modal: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid blue",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Header = (props) => {
  const [modalStyle] = useState(getModalStyle);
  const [openJoinRoom, setOpenJoinRoom] = useState(false);
  const [openCreateRoom, setOpenCreateRoom] = useState(false);
  const [nama, setNama] = useState("");
  const [year, setYear] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roomNotFound, setRoomNotFound] = useState(false);

  const userId = localStorage.getItem("user_id");
  const classes = useStyle();
  const history = useHistory();

  const { insertRow } = qoreContext.view("allClassroom").useInsertRow();

  async function addNewClass(e) {
    e.preventDefault();
    setIsLoading(true);
    await insertRow({
      name: nama,
      schoolYear: year,
      teacher: [userId],
      owner: [userId],
      inviteCode: uniqid.time(),
    })
      .then((room) => {
        props.onRoomAdded();
        setIsLoading(false);
        setOpenCreateRoom(false);
      })
      .catch((error) => {
        console.info(error);
      });
  }

  const handleJoinRoom = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const { data: roomToJoin } = await client
      .view("joinRoom")
      .readRows({ inviteCode: inviteCode || 123 })
      .toPromise();

    if (roomToJoin.nodes.length > 0) {
      setRoomNotFound(false);
      history.push(`joinroom/${roomToJoin.nodes[0].id}`);
    } else {
      setRoomNotFound(true);
    }
    setIsLoading(false);
  };

  const handleCreateClassroom = () => {
    setOpenCreateRoom(true);
  };

  const handleJoinClassroom = () => {
    setOpenJoinRoom(true);
  };

  const handleClose = () => {
    setOpenJoinRoom(false);
    setOpenCreateRoom(false);
  };

  const joinRoomModal = (
    <Modal
      open={openJoinRoom}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        className={classes.modal}
        style={modalStyle}
        onSubmit={handleJoinRoom}
      >
        <Typography variant="h6">Gabung Kelas</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          label="Masukkan Kode kelas"
          autoFocus
          fullWidth
          value={inviteCode}
          onChange={(e) => {
            setInviteCode(e.target.value);
            setRoomNotFound(false);
          }}
        ></TextField>
        <Collapse in={roomNotFound}>
          <Alert severity="warning">Kode salah, kelas tidak ditemukan</Alert>
        </Collapse>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            style={{ color: "GrayText", marginRight: 25 }}
            onClick={handleClose}
          >
            Batal
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={16} /> : null}
          >
            Gabung
          </Button>
        </Box>
      </form>
    </Modal>
  );

  const createRoomModal = (
    <Modal
      open={openCreateRoom}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form onSubmit={addNewClass} className={classes.modal}>
        <Typography variant="h6">Buat Kelas Baru</Typography>
        <div style={{ marginTop: 10 }}>
          <Typography variant="body2" style={{ marginBottom: -10 }}>
            Nama
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            placeholder="Masukan Nama kelas"
            autoFocus
            value={nama}
            fullWidth
            onChange={(e) => setNama(e.target.value)}
          ></TextField>
        </div>

        <div style={{ marginTop: 10 }}>
          <Typography variant="body2" style={{ marginBottom: -10 }}>
            Tahun Ajaran
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            placeholder="contoh: 2020/2021"
            fullWidth
            value={year}
            onChange={(e) => setYear(e.target.value)}
          ></TextField>
        </div>

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}
        >
          <Button
            size="small"
            style={{ color: "GrayText" }}
            onClick={handleClose}
          >
            Batal
          </Button>
          <Button
            color="primary"
            type="submit"
            size="small"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={16} style={{ marginRight: 10 }} />
            ) : null}
            Buat Kelas
          </Button>
        </div>
      </form>
    </Modal>
  );

  return (
    <>
      {joinRoomModal}
      {createRoomModal}
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ margin: "5em auto" }}
        >
          <Box>
            <Typography variant="h4" style={{ marginBottom: 12 }}>
              Ruang Kelas Anda
            </Typography>
            <Typography>Pilih dan kelola kelas Anda dengan mudah</Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.margin}
              onClick={handleCreateClassroom}
              disableElevation
            >
              + Tambah Kelas
            </Button>
            <Button
              id="btn-join"
              variant="outlined"
              color="primary"
              className={classes.margin}
              onClick={handleJoinClassroom}
            >
              Gabung Kelas
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Header;
