import React from "react";
import { Avatar, Typography, IconButton, Paper, Box } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { MoreVert } from "@material-ui/icons";

import { formatRelative } from "date-fns";
import { id as id_ID } from "date-fns/locale";

export default function CardMateri({ lesson }) {
  return (
    <Paper
      elevation={0}
      marginY={1}
      padding={1}
      border={1}
      borderColor="#D1D4DB"
      component={Box}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Avatar style={{ marginRight: 12 }} background="primary">
          <DescriptionIcon />
        </Avatar>
        <Typography
          variant="body1"
          style={{
            fontWeight: "bold",
          }}
        >
          {lesson.title}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography variant="caption">
          Dibuat pada {formatRelative(new Date(lesson.createdAt), new Date(), {
            locale: id_ID,
          })}
        </Typography>
        <IconButton aria-controls="edit-menu" aria-haspopup="true">
          <MoreVert />
        </IconButton>
      </Box>
    </Paper>
  );
}
