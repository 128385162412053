import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

// import redux
import { Provider } from "react-redux";
import store from "../store";

// import components
import Navbar from "../components/Navbar";

// import pages
import Login from "./Login/loginPage";
import Register from "./Register/registerPage";
import Dashboard from "./Dashboard/dashboard";
import RegisterTeacher from "./Register/registerTeacher";
import RegisterStudent from "./Register/registerStudent";
import RegisterSuccess from "./Register/register-success";
import ProfilePage from "./Profile/";
import Classroom from "./Classroom/insideClassroom";
import JoinRoom from "./JoinRoom";
import Module from "./Classroom/Lesson/module";
import Assignment from "./Classroom/Assignment/assignment";
import Score from "./Score/score";
import NotFoundPage from "./not-found";
import NetworkError from "./NetworkError";

import qoreContext from "../qoreContext";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
  },
  button: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
  },
});

export default function index() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <qoreContext.context.Provider
          value={{
            client: qoreContext.client,
          }}
        >
          <Router>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/register-success" component={RegisterSuccess} />
              <Route path="/register-teacher" component={RegisterTeacher} />
              <Route path="/register-student" component={RegisterStudent} />
              <Route path="/404" component={NotFoundPage} />
              <Route path="/offline" component={NetworkError} />
              <Route component={ProtectedRoutes} />
            </Switch>
          </Router>
        </qoreContext.context.Provider>
      </Provider>
    </ThemeProvider>
  );
}

function ProtectedRoutes() {
  if (localStorage.getItem("token") === null) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Navbar />
      <Route path="/" exact component={Dashboard} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/classroom/:someClassroomId" component={Classroom} />
      <Route path="/joinroom/:roomId" component={JoinRoom} />
      <Route path="/subject/:subjectId" component={Module} />
      <Route path="/assignment/:assignmentId" component={Assignment} />
      <Route path="/score" component={Score} />
    </>
  );
}